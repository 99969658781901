.work-sec-div1 {
  padding-bottom: 100px;
  width: 100%;
  overflow: hidden !important;
}

.work-selected-text11 {
  font-size: 7.6rem;
  color: #101118;
  font-family: Bebas neue, sans-serif;
  font-weight: 400;
  line-height: 1;
  margin-top: 75px;
}

.back-div1{
  background-color: #F0F2F5;
  position: absolute;
  min-height: 110px;
  width: 700px;
}

.work-selected-text12 {
  font-size: 1.125rem;
  line-height: 1.5;
  color: #6c757d;
  font-family: Manrope, sans-serif;
  background-color: #F0F2F5;
  z-index: 9999;
  min-width: 100%;
  position:relative;
}

.work-project-div11 {
  margin-top: 90px;
}

.work-project-title {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 1.1;
  color: #101118;
  font-family: Bebas neue, sans-serif;
  margin-top: 24px;
}

.work-project-date {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: Manrope, sans-serif;
  color: #6c757d;
}

.work-projects-hr {
  width: 100%;
  height: 1px;
  background-color: #ced4da;
  margin-top: 35px;
}

.work-project-href:hover {
  color: none !important;
  text-decoration: none !important;
}


@media only screen and (max-width: 576px) {

  .work-selected-text11{
    font-size: 3rem;
  }

  .work-project-title{
    font-size: 2rem;
  }

  .proj-img11{
    max-width: 330px !important;
    height: 237px !important;
  } 

  .work-project-div11 {
    margin-top: 60px;
    display: grid;
    place-content: center;
}
.work-projects-hr{
  margin-top: 20px;
}

.back-div1{
  
  width: 300px;
}

}