.contact-div1 {
  padding: 100px 150px;
  padding-bottom: 200px;
  /* background-color: aqua; */
}

.contact-text1 {
  font-size: 7.6rem;
  color: #101118;
  font-family: Bebas neue, sans-serif;
  font-weight: 400;
  line-height: 1;
}

.contact-text2 {
  font-size: 1.125rem;
  line-height: 1.5;
  color: #6c757d;
  font-family: Manrope, sans-serif;
  background-color: #F0F2F5;
  position: relative;
  z-index: 4;
}

.contact-text-div1 {
  margin-right: 50px;
}

.contact-card-div {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  padding: 2rem;
  transition: background-color 0.4s, transform 0.3s;
  margin-top: 40px;
}


.contact-card-div1 {
  background-color: #101118;
  border: 1px solid #101118;
  padding: 2.3rem;
  transition: background-color 0.4s, transform 0.3s;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-card-div1:hover{
  background-color: #2b2f3a;
}

.contact-card-div:hover {
  opacity: 1;
  background-color: #e4e7eb;
}

.contact-port-btn1{
  color: #FFF;
font-family: Manrope;
font-size: 30px;
font-style: normal;
font-weight: 600;
text-decoration-line: underline;
text-transform: uppercase;
}

.contact-port-btn1:hover{
  color: #FFF;
}

.contact-card-text1 {
  color: #101118;
  font-size: 1.125rem;
  line-height: 1.3;
  font-family: Manrope, sans-serif;
  font-weight: 600 !important;
}

.contact-card-text2 {
  font-size: 1.125rem;
  line-height: 1.5;
  color: #6c757d;
  font-family: Manrope, sans-serif;
  font-weight: 600 !important;
  margin-bottom: 0px !important;
}

.form-main-div {
  min-height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-input-field1 {
  border: none;
  border-bottom: 2px solid #ced4da;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 1rem;
  transition: border-color 0.3s;
  min-height: 3.5rem;
  color: #2b2f3a;
  background-color: transparent;
  margin-bottom: 0;
  padding: 0;
  line-height: 1.5;
  /* color: #6c757d; */
  font-family: Manrope, sans-serif;
  font-weight: 600;
}

.form-input-div2 {
  margin-top: 50px;
}

.contact-input-field1::placeholder {
  font-size: 1rem;
  line-height: 1.5;
  color: #6c757d;
  font-family: Manrope, sans-serif;
  font-weight: 600;
}

.contact-us-btn1 {
  width: 9rem;
  height: 3.5rem;
  background-color: #101118;
  border: 1px solid #000;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: Manrope, sans-serif;
  font-size: 0.675rem;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  margin-top: 35px;
  margin-left: 15px !important;
}

.contact-us-btn1:hover {
  background-color: #fff;
  border: 1px solid #000;
  color: #101118;
  text-decoration: none;
}

.contact-input-field1:hover {
  border-bottom-color: #6c757d;
}

.contact-input-field1:focus {
  border-color: #6c757d !important;
  outline: 0 !important;
  box-shadow: none !important;
}


@media only screen and (max-width: 576px) {
  .contact-text1{
    font-size: 3rem;
  
}
.contact-div1 {
    padding: 80px 20px;
    padding-bottom: 120px;
    /* background-color: aqua; */
}

.form-main-div{
  min-height: 200px;
  padding-top: 50px;
  padding-bottom: 55px;
  margin-top: 60px;
}

.for-responsive-div12{
  padding: 0px !important;
}

.contact-text-div1{
  margin-right: 0px;
}

.contact-port-btn1{
  font-size: 24px;
}
}
