@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.home-sec-div1 {
  background-color: #f0f2f5;
  min-height: 51.5vh;
  /* display: flex; */
  /* align-items: center; */
}

.profile-text1{
  font-family: Bebas neue, sans-serif;
  font-weight: 400;
  line-height: 1.15 !important;
  color: #5e6065;
  font-size: 7.6rem;
  /* 7.6rem */
  text-align: center;
  margin-top: 80px;
  /* display: inline; */
  opacity: 1;
}

.profile-text1 .react-reveal{
  display: inline-flex;
}

.haveproject-text1 .react-reveal{
  display: inline-flex;
}

.profile-text1 .profile-text1-span1{
  color: #101118;
}

.profile-img1 {
  width: 93px;
  height: 93px;
  object-fit: cover;
  border-radius: 100%;
}

.profile-img2 {
  width: 128px;
  height: 88px;
  object-fit: cover;
  /* border-radius:100%; */
}

.profile-img3 {
  width: 93px;
  height: 93px;
  background-image: linear-gradient(#adb5bd, #f0f2f5);
  border-radius: 100%;
  display: inline-block;
}

.home-btn-div1 {
  margin-top: 50px !important;
}

.view-all-btn1 {
  width: 600px;
  height: 3.8rem;
  background-color: #101118;
  border: 1px solid #000;
  border-radius: 40px;
  align-items: center;
  justify-content:center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: Manrope, sans-serif;
  font-size: 0.675rem;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  position: relative;
}
.btn-svg11{
  position: absolute;
  left: 4.5px;
  top: 3.5px;
  transition: all 0.3s ease-in-out;
}



.view-all-btn1:hover .btn-svg11 circle{
  fill: #101118 !important;
}

.view-all-btn1:hover {
  background-color: #f0f2f5;
  border: 1px solid #000;
  color: #101118;
  text-decoration: none;
}

.view-all-btn2 {
  width: 11rem;
  height: 3.5rem;

  border: 1px solid #000;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
  color: #101118;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: Manrope, sans-serif;
  font-size: 0.675rem;
  line-height: 1;
  transition: all 0.3s ease-in-out;
}

.view-all-btn2:hover {
  background-color: #101118;
  border: 1px solid #000;
  color: #fff;
  text-decoration: none;
}

.btn-line1 {
  width: 7rem;
  height: 1px;
  background-color: #202026;
}

/* section no 2 */

.animation-div1 {
  justify-content: center;
  align-items: flex-end;
  display: flex;
  overflow: hidden;
}

.animation-pic1 {
  will-change: transform;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}

.home-sec-div2 {
  background-color: #101118;
  padding-top: 110px;
  padding-bottom: 140px;
  /* margin-left: -80px; */
}

.wonder-text1 {
  font-family: Bebas neue, sans-serif;
  font-size: 6.8rem;
  font-weight: 400;
  line-height: 1.1;
  color: #fff;
  margin-left: 80px;
}

.wonder-text-div1 {
  width: 4rem;
  height: 2px;
  background-color: #fff;
  margin-bottom: 1rem;
  display: inline-block;
  margin-right: 12px;
}

.wonder-text-div2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-sec-text2 {
  margin-right: 280px;
  margin-top: 40px;
}

.home-sec-text2 p {
  font-family: Manrope, sans-serif;
  font-size: 1.5rem;
  line-height: 1.5;
  color: #adb5bd;
  font-weight: 400 !important;

}

.more-text1 {
  font-family: Manrope, sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #fff;
  font-weight: 400 !important;
}

.more-text1:hover {
  color: #fff;
}

.home-more-text1 {
  font-family: Manrope, sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  color: #101118;
  font-weight: 400 !important;
}

.home-more-text1:hover {
  color: #101118;
}

.home-more-text1 img{
  filter: brightness(0.35);
}

.home-aout-divs11{
margin-top: -180px;

}


.home-about-year-text2 {
  color: #fff;
  /* padding-top: 5px;
    padding-bottom: 5px; */
  font-family: Bebas neue, sans-serif;
  font-size: 5rem;
  font-weight: 400;
  /* margin-top : -15px; */
  /* margin-bottom: 150px; */
  position: absolute;
  transform: translateY(-10px);
}


/* section no 3 */

.home-sec-div3 {
  padding-top: 150px;
  padding-bottom: 150px;
}

.what-text1 {
  font-size: 6.8rem;
  font-weight: 400;
  line-height: 0.9;
  font-family: Bebas neue, sans-serif;
  color: #101118;
}

.cards-div1 {
  margin-top: 120px;
}

.circle-icon1 {
  width: 4rem;
  height: 4rem;
  position: relative;
}

.circle-icon1-div1 {
  width: 2rem;
  height: 2rem;
  background-color: #6c757d;
  border-radius: 100%;
  position: absolute;
}

.circle-icon1-div2 {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  position: absolute;
  background-color: #adb5bd;
  left: 25%;
}

.circle-icon1-div3 {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  position: absolute;
  background-color: #ced4da;
  top: 15%;
  left: 10%;
}

.concept-text1 {
  color: #202026;
  font-size: 1.5rem;
  line-height: 1.5;
  font-family: Manrope, sans-serif;
  font-weight: 600;
}

.concept-text2 {
  color: #6c757d;
  font-size: 1.125rem;
  line-height: 1.5;
  font-family: Manrope, sans-serif;
  font-weight: 500;
  margin-top: 13px;
}

.card-text-div1 {
  margin-right: 15px;
}

.card-text-div2 {
  margin-top: 50px;
  /* margin-left: 30px; */
  margin-right: 15px;
}

.card-text-div3 {
  margin-top: 100px;
  /* margin-left: 30px; */
  margin-right: 15px;
}

.circle-icon2-div1 {
  width: 2rem;
  height: 2rem;
  background-color: #6c757d;
  border-radius: 100%;
  position: absolute;
}

.circle-icon2-div2 {
  width: 2rem;
  height: 2rem;
  background-color: #6c757d;
  border-radius: 100%;
  position: absolute;
  background-color: #adb5bd;
  left: 20%;
}

.circle-icon2-div3 {
  width: 2rem;
  height: 2rem;
  background-color: #6c757d;
  border-radius: 100%;
  position: absolute;
  background-color: #ced4da;
  left: 40%;
}

.circle-icon3-div1 {
  width: 2rem;
  height: 2rem;
  background-color: #6c757d;
  border-radius: 100%;
  position: absolute;
}

.circle-icon3-div2 {
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  position: absolute;
  background-color: #adb5bd;
  top: 20%;
}

.circle-icon3-div3 {
  width: 2rem;
  height: 2rem;
  background-color: #ced4da;
  top: 10%;
  left: 25%;
  border-radius: 100%;
  position: absolute;
}

/* section no 4 */

.home-sec-div4 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.selected-text1 {
  font-size: 6.8rem;
  font-weight: 400;
  line-height: 0.9;
  font-family: Bebas neue, sans-serif;
  color: #101118;
}

.selected-para1 {
  font-size: 1.125rem;
  line-height: 1.5;
  font-weight: 400;
  font-family: Manrope, sans-serif;
  color: #6c757d;
  margin-top: 15px;
  position: relative;
  z-index: 3;
  background-color: #F0F2F5;
}

.project-main-div {
  margin-top: 100px;
}

.project1-div1 {
  width: 100%;
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.project1-div2 {
}

.project1-link1 {
  width: 100%;
  height: 100%;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: transform 0.3s;
  position: relative;
  display: inline-block;
}

.project1-img1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  will-change: transform;
  transform: translate3d(0px, 0px, 0px) scale3d(1.05652, 1.05652, 1)
    rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  /* position: sticky; */
}

.project2-div1 {
  width: 100%;
  height: 100vh;
  /* position: -webkit-sticky; */
  position: sticky;
  top: 0rem !important;
  overflow: hidden;
}

.project3-div1 {
  width: 100%;
  height: 100vh;
  /* position: -webkit-sticky; */
  position: sticky;
  top: 0rem !important;
  overflow: hidden;
}

.count-top-div {
  z-index: 2;
  width: 100%;
  height: 4rem;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.count-top-text1 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-family: Manrope, sans-serif;
  color: #fff;
  font-style: italic;
  margin-top: 12px;
}

.project-name-div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 40%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.project-name-text1 {
  font-size: 6.8rem;
  font-weight: 400;
  line-height: 0.9;
  margin-top: 0;
  margin-bottom: 0;
  font-family: Bebas neue, sans-serif;
  color: #fff;
}

/* section no 5 */

.home-sec-div5 {
  padding-top: 80px;
  padding-bottom: 130px;
}

.haveproject-text1 {
  font-family: Bebas neue, sans-serif;
  font-weight: 400;
  line-height: 134px !important;
  color: #5e6065;
  font-size: 7.6rem;
  text-align: center;
}

.haveproject-text1 .have-project-span {
  color: #101118;
}

.haveproject-btn1 {
  width: 11rem;
  height: 3.5rem;
  background-color: #101118;
  border: 1px solid #000;
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-family: Manrope, sans-serif;
  font-size: 0.675rem;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  margin-bottom: 1rem;
  margin-left: 30px;
}

.haveproject-btn1:hover {
  background-color: #f0f2f5;
  border: 1px solid #000;
  color: #101118;
  text-decoration: none;
}

.have-div1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.agency-div1 {
  margin-top: 80px;
}

.agency-text1 {
  font-size: 1.05rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c757d;
  font-family: Manrope, sans-serif;
  text-transform: uppercase;
  text-align: center;
}

.work-col-div {
  width: 210px;
  margin-right: 30px;
}

.footer-div1{
  padding-top: 40px;
  padding-bottom: 40px;
}


.last-icons-div11{
  column-gap: 20px;
  /* margin-top: 40px; */
}

.weqasim-email{
color: #101118;
text-align: center;
font-family: Manrop, sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-decoration-line: underline;
text-align: start;
/* margin-top: 20px; */
display: flex;
justify-content: center;
}

.weqasim-email:hover{
  color: #101118;
}


@media only screen and (max-width: 991px) {
.profile-text1{
  font-size: 5rem;
}

}



@media only screen and (max-width: 576px) {
  .home-sec-div1{
    min-height: 50vh;
  }
.profile-text1{
  font-size: 3.3rem;
  line-height: 70px !important;
  margin-top: 40px;
}
.profile-img1{
  width: 40px;
  height: 40px;
}
.profile-img2{
  width: 64px;
  height: 42px;
}
.profile-img3{
  width: 42px;
  height: 42px;
  transform: translateY(6px);
}

.view-all-btn1{
  width: 18rem;
  font-size: 0.6rem;
}
.btn-line1{
  width: 3rem;
}
.view-all-btn2{
  width: 13.5rem;
  font-size: 0.6rem;
}

.wonder-text1{
  font-size: 3rem;
  margin-left: 15px;
}

.wonder-text-div1{
  width: 2rem;
  margin-bottom: 0px;
}

.wonder-text-div2{
  justify-content: start;
}

.home-sec-text2{
margin-right: 15px;
}

.home-sec-text2 p{
  font-size: 1.3rem;
  margin-left: 15px;
  /* margin-right: 15px; */

}

.home-aout-divs11{
margin-top: 0px;

}


.more-text1{
  margin-top: 0px;
  margin-right: 15px;
}

.cards-div1{
  margin-top: 80px;
}
.card-text-div3{
  margin-top: 50px;
}

.selected-text1{
  font-size: 3rem;
  padding-left: 15px;
}
.selected-para1{
  padding-left: 15px;
}

.home-sec-div4{
  padding-top: 60px;
}

.what-text1{
  font-size: 3.4rem;
  /* text-align: center; */
}

.project-name-text1{
  font-size: 5rem;
}

.haveproject-text1{
  font-size: 3rem;
  line-height: 70px !important;
}

.haveproject-btn1{
  width: 9rem;
  margin-left: 12px;
  height: 3rem;
}

.have-div1{
  display: inline-flex;
  margin-left: 8px;
}

.home-sec-div5{
  padding-top: 30px;
  padding-bottom: 70px;
}

.project1-img1{
  height: 100vh !important;
  object-fit: cover;
  /* background-position: c; */
}

}
