@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.navbar-div1{
    padding-top: 35px;
    padding-bottom: 37px;
    background-color: #F0F2F5;
   
}

.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
 background-color: #101118;

  /* background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9); */
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay1 {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
 background-color: #101118;
  overflow-x: hidden;
  transition: 0.5s;
}

.overlay-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  /* margin-top: 30px; */
}

.overlay a{
  text-decoration: none;
  /* display: block; */
  transition: 0.3s;
}

.overlay1 a{
 
  text-decoration: none;
  /* display: block; */
  transition: 0.3s;
  color: #101118 !important;
}

.overlay1 a span{
 
  text-decoration: none;
  /* display: block; */
  transition: 0.3s;
  color: #101118 !important;
}

.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

.overlay1 a:hover, .overlay1 a:focus {
  color: #f1f1f1;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 100px;
  font-size: 60px;
  color: #fff;
}

.overlay1 .closebtn {
  position: absolute;
  top: 20px;
  right: 100px;
  font-size: 60px;
  color: #fff;
}


.nav-text1{
    font-family: 'Manrope', sans-serif;
    font-size: 80px !important;
    color: #fff !important;
    display: flex;
    align-items: start;
    font-weight: 600;
    /* justify-content: center; */
    /* text-align: justify; */
    
}

.nav-text1 span{
    color: #fff;
    margin-top: 2em;
    margin-left: 12px;
    font-family: Manrope,sans-serif;
    font-size: .675rem;
    line-height: 1;
   /* margin-top: auto ; */

}

.nav-text-div1{
    display: grid;
    place-content: center;
}

.logo-text1{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 34px;
    top: 47px;
    position: absolute;
    left: 100px;
    color: #fff;
}

.logo-text2{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 34px;
    top: 46px;
    position: absolute;
    left: 140px;
    color:#101118;
}




.navbar-btn1{
    font-size: 36px;
    cursor: pointer;
    /* margin-right: 100px; */
    margin-right: 50px;
    margin-left: 50px;
}

.overlay1 div .logo-text1{
    color: #101118 !important;
}

.icons-div11{
  margin-right: 60px;
  column-gap: 10px;
}



.desk-logo-text2{
    font-family: 'Bebas Neue', sans-serif;
    font-size: 34px;
    margin-left: 50px;
    width: 180px;
    color:#101118;
    margin-bottom: 0px;
    margin-top: 5px;
}


.desk-nav-li1{
  color: #000;
font-family: Manrope;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 32px; /* 189.474% */
}


.desk-navbar-ul{
  margin-left: -30px;
  border-radius: 100px;
/* border: 1px solid #000;
padding-left: 30px;
padding-right: 30px; */
column-gap: 17px;

}


.dropdown-menu-div {
    background: #101118;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.149);
    /* margin-left: 40px; */
    margin-top: 7px;
}

.dropdown-item-text {
font-family: Manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: #F0F2F5;
}

.dropdown-item-text:hover {
    color: #fff;
    background-color: #101118;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #101118;
}


.navbar-div1 div a:hover{
  text-decoration: none;
}



@media only screen and (max-width: 991px) {
.navbar-div1{
  padding-top: 25px;
  padding-bottom: 35px;
}
.overlay-content{
  top: 17%;
}
.logo-text2{
  left: 30px;
  top: 32px;
  font-size: 28px;
}


.navbar-btn1{
  margin-right: 30px;
  font-size: 32px;
}

.logo-text1{
 left: 30px;
  top: 32px;
  font-size: 28px;
}
.overlay a {font-size: 20px}
  .overlay .closebtn {
  font-size: 45px;
  top: 15px;
  right: 30px;
  }

  .nav-text1{
    font-size: 2.5rem !important;
    text-align: center;
    display: block !important;
    margin-top: 20px;
    
  }
  .nav-text-div1{
    display:grid;
    justify-content: center;
  }

}



@media only screen and (max-width: 576px) {


}


